import React from 'react';
import * as Utils from '../utils';

interface IGoogleLoginProps {
  children: (arg: IGoogleResponseProps) => React.ReactElement | null;
}

interface IAuthResponse {
  status?: {
    method: string;
  };
  code?: string;
  access_token?: string;
  error?: any;
}

interface IGoogleResponseProps {
  loading: boolean;
  auth: {
    code?: string;
    accessToken?: string;
  };
}

const GoogleLogin = (props: IGoogleLoginProps) => {
  const [auth, setAuth] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    gapi.load('client', () => {
      gapi.auth.signIn({
        clientid: Utils.getGoogleClientId(),
        scope: 'email',
        cookiepolicy: 'single_host_origin',
        accesstype: 'offline',
        redirecturi: 'postmessage',
        callback(authResult: IAuthResponse) {
          // If request is automatic return, we should wait for the user selection
          if (
            authResult &&
            authResult.status &&
            authResult.status.method !== 'PROMPT'
          ) {
            return;
          }

          if (authResult.error === 'user_signed_out') {
            console.error('user is logged out');
            setLoading(false);
            return;
          }

          if (
            authResult.code !== undefined &&
            authResult.access_token !== undefined
          ) {
            setLoading(false);
            setAuth({
              code: authResult.code,
              accessToken: authResult.access_token,
            });
          }
        },
      });
    });
  }, []);

  return props.children({ auth, loading });
};

export default GoogleLogin;
