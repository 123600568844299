import Cookies from 'js-cookie';
import ButtonGoogle from 'p4-ui/lib/molecules/ButtonGoogle';
import React from 'react';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';
import styled from 'styled-components';
import * as Constants from '../constants';
import * as Utils from '../utils';
import GoogleLogin from './GoogleLogin';
import PlatformAuth, { IPlatformResponse } from './PlatformAuth';

const Wrapper = styled.div`
  margin: 40px auto;
  max-width: 400px;
  text-align: center;
`;

const TextWrapper = styled.div`
  font-family: helvetica, arial, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Auth = ({ location }: { location: any }) => {
  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirectUrl');
  const [clicked, setClicked] = React.useState(false);

  console.log('FOO: ', Utils.getCookieDomain());

  const onClick = () => {
    setClicked(true);
  };

  React.useEffect(() => {
    Cookies.remove(Constants.SSO_COOKIE_RESPONSE_KEY);
  }, []);

  return (
    <Wrapper>
      <TextWrapper>Platform SSO</TextWrapper>
      <ButtonGoogle onClick={onClick} disabled={clicked} />
      {clicked && (
        <GoogleLogin>
          {({ loading, auth: { code, accessToken } }) => {
            if (code && accessToken) {
              return (
                <PlatformAuth
                  accessToken={accessToken}
                  code={code}
                  params={params}
                >
                  {(response: IPlatformResponse) => {
                    console.info(
                      `setting ${
                        Constants.SSO_COOKIE_RESPONSE_KEY
                      } ${JSON.stringify(response)}`,
                    );

                    Cookies.set(Constants.SSO_COOKIE_RESPONSE_KEY, response, {
                      domain: Utils.getCookieDomain(),
                    });

                    if (response) {
                      if (response.error) {
                        return (
                          <TextWrapper>
                            {`Platform auth failed with: ${response.error} ${response.message}`}
                          </TextWrapper>
                        );
                      }

                      if (redirectUrl) {
                        window.location.assign(redirectUrl);
                      } else {
                        return <TextWrapper> {'authenticated'}</TextWrapper>;
                      }
                    }

                    return null;
                  }}
                </PlatformAuth>
              );
            }

            if (!loading) {
              return <TextWrapper>{'cannot login to google'}</TextWrapper>;
            }

            return null;
          }}
        </GoogleLogin>
      )}
    </Wrapper>
  );
};
const Application = () => {
  return (
    <Router>
      <Route component={Auth} />
    </Router>
  );
};

export default Application;
