import { getCookieDomainConfig } from './config';

const TOP_LEVEL_DOMAIN_REGEX = /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/;

export function getTopLevelDomain() {
  const match = window.location.hostname.match(TOP_LEVEL_DOMAIN_REGEX);
  return match ? `.${match[0]}` : '';
}

export function getCookieDomain() {
  const domainConfig = getCookieDomainConfig();
  if (domainConfig) {
    return domainConfig;
  }

  return getTopLevelDomain();
}
