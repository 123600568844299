console.log(CONFIG);

export function getGoogleClientId() {
  return CONFIG.GOOGLE_CLIENT_ID;
}

export function getPlatformAuthUrl() {
  return CONFIG.PLATFORM_AUTH_URL;
}

export function getPlatformAuthClientId() {
  return CONFIG.PLATFORM_AUTH_CLIENT_ID;
}

export function getCookieDomainConfig() {
  return CONFIG.COOKIE_DOMAIN;
}
