import React from 'react';
import * as Utils from '../utils';

export interface IPlatformAuthProps {
  children: (arg: any) => any;
  accessToken: string;
  code: string;
  params: URLSearchParams;
}

export interface IPlatformResponse {
  token_type: string;
  access_token: string;
  error?: string;
  message?: string;
}

const PlatformAuth = (props: IPlatformAuthProps) => {
  const [
    authResponse,
    setAuthResponse,
  ] = React.useState<IPlatformResponse | null>(null);

  const clientId =
    props.params.get('clientId') || Utils.getPlatformAuthClientId();

  React.useEffect(() => {
    const data = {
      access_token: props.accessToken,
      code: props.code,
      client_id: clientId,
      scope: '',
      grant_type: 'google_one_time_code',
    };

    const xhr = new XMLHttpRequest();
    // xhr.withCredentials = true;

    xhr.addEventListener('readystatechange', function() {
      if (this.readyState === 4) {
        const response = JSON.parse(this.responseText) as IPlatformResponse;
        const header = response.token_type + ' ' + response.access_token;
        console.info(
          'This is the value you will need to use in the header Authorization ',
          header,
        );

        setAuthResponse(response);
      }
    });

    xhr.open('POST', Utils.getPlatformAuthUrl());
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.send(JSON.stringify(data));
  }, []);

  return props.children(authResponse);
};

export default PlatformAuth;
